<template>
  <div class="container">
    <div class="toolBox">
      <div id="e-shop" class="e-shop">
        <a
          >购买</a
        >
      </div>
      <div id="pre-sale" class="pre-sale">
        <a href="javascript:void(0);">咨询</a>
      </div>
      <div id="after-sale" class="after-sale">
        <a href="javascript:void(0);">售后</a>
      </div>
    </div>
    <!-- <div id="pre-sale-content" class="consult-sub consult-pre-sale">
      <div class="title">售前</div>
      <div class="phone">
        <b>电话咨询</b>
        <br />
        400-806-6866转1
        <br />
        0592-3135688
      </div>
      <div class="qq cp">
        <div
          rel="external nofollow"
        >
          <b>售前咨询</b>
          <br />
          800008626
        </div>
      </div>
      <div class="weixin">
        <b>售后咨询</b>
        <br />
        扫一扫联系银豹售后
      </div>
    </div>
    <div id="after-sale-content" class="consult-sub consult-after-sale">
      <div class="title">售后</div>
      <div class="phone">
        <b>电话咨询</b>
        <br />
        400-806-6866转2
      </div>
      <div class="qq cp">
        <div
          rel="external nofollow"
        >
          <b>投诉通道</b>
          <br />
          3487430600
        </div>
      </div>
      <div class="weixin">
        <b>微信咨询</b>
        <br />
        扫一扫联系店教授售后
      </div>
    </div> -->

    <div class="section section_one">
      <div class="btn absolute horMid cp">
        <div
          rel="external nofollow"
          class="consult"
        ></div>
      </div>
    </div>

    <div class="section section_two"></div>

    <div class="section section_three"></div>

    <div class="section section_four"></div>

    <div class="section section_five"></div>
  </div>
</template>

<script>
import '../../../assets/css/faceRecognition/common.css'
import '../../../assets/css/faceRecognition/init.css'
export default {};
</script>

<style scoped>
.container{
    width: 100%;
}
.container .toolBox {
  position: fixed;
  width: 80px;
  height: 266px;
  background-image: url("../../../assets/images/product/FaceRecognition/consult.png");
  background-size: cover;
  right: 0;
  bottom: 0;
  z-index: 10;
  /* display: none; */
}
.container .toolBox .after-sale,
.container .toolBox .e-shop,
.container .toolBox .pre-sale {
  font-size: 14px;
  position: absolute;
  width: 100%;
  height: 110px;
  text-align: center;
  padding: 20px 0 0 0;
  cursor: pointer;
}
.container .toolBox .e-shop {
  top: -5px;
}
.container .toolBox .e-shop a {
  display: block;
  height: 100%;
  width: 100%;
}
.container .toolBox .pre-sale {
  top: 80px;
}
.container .toolBox .after-sale {
  top: 173px;
}
.container .consult-after-sale,
.container .consult-pre-sale {
  display: none;
}
.container .consult-sub {
  background-image: url("../../../assets/images/product/FaceRecognition/consult-sub.png");
  background-size: cover;
  position: fixed;
  width: 236px;
  height: 436px;
  right: 78px;
  bottom: 0;
  z-index: 10;
}
.container .consult-sub .title {
  font-size: 18px;
  text-align: center;
  margin: 10px 0 0 0;
  display: none;
}
.container .consult-sub .phone,
.container .consult-sub .qq,
.container .consult-sub .weixin {
  font-size: 14px;
  position: absolute;
  width: 100%;
  text-align: left;
  padding: 0 0 0 69px;
}
.container .consult-sub .phone {
  top: 45px;
}
.container .consult-sub .qq {
  top: 155px;
}
.container .consult-sub .qq:hover {
  color: #ff6727;
}
.container .consult-sub .weixin {
  top: 245px;
}
.container .section {
  width: 100%;
}
.container .section_one {
  height: 515px;
  background: url("../../../assets/images/product/FaceRecognition/bg_01.png") no-repeat top center;
  background-size: cover;
}
.container .section_one .btn {
  top: 305px;
  width: 252px;
  height: 90px;
  margin-left: 198px;
}
.container .section_one .btn .consult {
  margin-top: 4px;
  width: 252px;
  height: 90px;
  border-radius: 30px;
  background: url("../../../assets/images/product/FaceRecognition/button_banner.png") no-repeat top center;
  background-size: cover;
}
.container .section_one .btn:hover .consult {
  margin-top: 0;
}
.container .section_two {
  height: 1187px;
  background: url("../../../assets/images/product/FaceRecognition/bg_02.png") no-repeat top center;
  background-size: cover;
}
.container .section_three {
  height: 820px;
  background: url("../../../assets/images/product/FaceRecognition/bg_03.png") no-repeat top center;
  background-size: cover;
}
.container .section_four {
  height: 969px;
  background: url("../../../assets/images/product/FaceRecognition/bg_04.png") no-repeat top center;
  background-size: cover;
}
.container .section_five {
  height: 716px;
  background: url("../../../assets/images/product/FaceRecognition/bg_05.png") no-repeat top center;
  background-size: cover;
}
.container .section_six {
  height: 562px;
  /* background: url("../../../assets/images/product/FaceRecognition/bg_06.png") no-repeat top center; */
}
.container .section_six .btn {
  /* top: 432px; */
  width: 185px;
  height: 74px;
  margin-left: 188px;
}
.container .section_six .btn .flashSale {
  margin-top: 4px;
  width: 185px;
  height: 74px;
  border-radius: 30px;
  /* background: url("../../../assets/images/product/FaceRecognition/button_end.png") no-repeat top center; */
}
.container .section_six .btn:hover .flashSale {
  margin-top: 0;
}
</style>